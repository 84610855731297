import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Side from "../components/Side"
import styled from "../components/SideProjects.css"


const IndexPage = () => (

    <div className="SideProjects">
    
        <div className="LeftContent">
            <Link href="./">
                <div className="BackHome"></div>
            </Link>
                <div className ="Content">
                    <h1>Red Design Darkmode <br/>Design Proposal</h1>
                    <h3>01<span>/02</span></h3>            
                </div>
                <div className="Description01">
                    <p>By defining the basic color names in Light and Dark modes, <span>One Click</span> switching of colors is guaranteed.</p>
                </div>
                <div className="Link">
                    <Link href="https://redgalaxy.netlify.app/" target="_blank">
                        <div className="LinkLeft">
                            <h3>Launch Website</h3>
                            <div className="LeftImage"></div>
                        </div>
                    </Link>
                </div>
                
        </div>

        <div className="RightContent">
            <div className ="Content02">
                <h1>Design Resoure UIKit<br/>Markkit Flowkit</h1>
                <h3>02<span>/02</span></h3>
            </div>
            <div className="Description02">
                <p>Define <span>Markkit and Flowkit</span> in a componentized way for quick design description.</p>
            </div>
            <div className="Link">
            <div className="LinkRight">
                <h3>Free Download</h3>
                <div className="RightImage"></div>
            </div>
            </div>
        </div>
        
    </div>
    
    
  
)

export default IndexPage
