import React from 'react'
import styled from 'styled-components'

const SideContent = styled.div`    
`

const Title = styled.h1``

const Number = styled.h3``

const Text = styled.p``

const LinkGroup = styled.div``

const LinkName = styled.h3``

const LinkImage = styled.div``

const Side = props => (
    <div>
        <SideContent>
            <Title>{props.title}</Title>
            <Number>{props.number}</Number>
            <Text>{props.text}</Text>
            <LinkGroup>
                <LinkName>{props.linkname}</LinkName>
                <LinkImage>{props.Linkimage}</LinkImage>
            </LinkGroup>
        </SideContent>
    </div>
)

export default Side